import React from "react";

const CopyRight = () => {
  return (
    <div className="align-items-center justify-content-between">
      <p className="copyright text-center">
        Copyright @{new Date().getFullYear()}{" "} Ptchk Apps OÜ<br/>
        Harju maakond, Tallinn, Kesklinna linnaosa, Vesivärava tn 50-201, 10152
      </p>
    </div>
  );
};

export default CopyRight;
