import React  from "react";

const HeaderLandingMobileApp = () => {
  return (
    <>
      {/* =============================================
				Theme Main Menu
			==============================================  */}
      <div className="theme-main-menu theme-menu-five">
        <div className="d-flex align-items-center justify-content-center">
          <div className="logo">
            <strong>Ptchk Apps</strong>
          </div>
          {/* End logo */}

          <div className="right-widget">
            <ul className="d-flex align-items-center">
              <li>
                <div className="dropdown download-btn style-two">
                  <a href="https://apps.apple.com/developer/id1683097913" className="appstore-button">App Store</a>
                </div>
              </li>
            </ul>
          </div>
          {/* End right-widget */}
        </div>
      </div>
      {/* /.theme-main-menu */}
    </>
  );
};

export default HeaderLandingMobileApp;
